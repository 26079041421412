import { userConstants } from '../constants'
import { userService } from '../services'
import { history } from '../helpers'
import { notifierActions } from './notifier.actions'
import { notificationService } from '../services/notification.service'

export const userActions = {
  login,
  logout,
  register,
  changePassword,
  forgotPassword,
  getAll,
  delete: _delete,
  getUserNotifications,
  magicRegisterSetPassword,
  closeNotification,
}

function login (email, password) {
  return dispatch => {
    dispatch(request({ email }))

    userService.login(email, password)
      .then(
        user => {
          dispatch(success(user))
          const redirectLocation = localStorage.getItem('redirect');
          if (redirectLocation) {
            localStorage.removeItem('redirect')
            window.location = redirectLocation;
          } else {
            history.push('/mobile')
          }
        },
        error => {
          dispatch(failure(error))
          dispatch(notifierActions.enqueueSnackbar({
            message: error.toString(),
            options: {
              variant: 'error',
            },
          }))
        }
      )
  }

  function request (user) {
    return { type: userConstants.LOGIN_REQUEST, user }
  }

  function success (user) {
    return { type: userConstants.LOGIN_SUCCESS, user }
  }

  function failure (error) {
    return { type: userConstants.LOGIN_FAILURE, error }
  }
}

function logout () {
  userService.logout()
  return { type: userConstants.LOGOUT }
}

function register (user) {
  return dispatch => {
    dispatch(request(user))

    userService.register(user)
      .then(
        () => {
          dispatch(success())
          dispatch(login(user.email, user.password))
        },
        error => {
          dispatch(failure(error))
          dispatch(notifierActions.enqueueSnackbar({
            message: error.toString(),
            options: {
              variant: 'error',
            },
          }))
        }
      )
  }

  function request (user) {
    return { type: userConstants.REGISTER_REQUEST, user }
  }

  function success (user) {
    return { type: userConstants.REGISTER_SUCCESS, user }
  }

  function failure (error) {
    return { type: userConstants.REGISTER_FAILURE, error }
  }
}

function getAll () {
  return dispatch => {
    dispatch(request())

    userService.getAll()
      .then(
        users => dispatch(success(users)),
        error => dispatch(failure(error))
      )
  }

  function request () {
    return { type: userConstants.GETALL_REQUEST }
  }

  function success (users) {
    return { type: userConstants.GETALL_SUCCESS, users }
  }

  function failure (error) {
    return { type: userConstants.GETALL_FAILURE, error }
  }
}

function changePassword (forgotKey, newPassword) {
  return dispatch => {
    return userService.changePassword(forgotKey, newPassword)
      .then(
        users => history.push('/mobile'),
        error => {
          const message = error.status = 451 ? 'The link might have already been used in the past. Try generating a new one and try again' : error.toString()
          return dispatch(notifierActions.enqueueSnackbar({
            message,
            options: {
              variant: 'error',
            },
          }))
        }
      )
  }
}

function magicRegisterSetPassword (magicKey, newPassword) {
  return dispatch => {
    return userService.magicRegisterSetPassword(magicKey, newPassword)
      .then(
        data => (data), //history.push('/login'),
        error => dispatch(notifierActions.enqueueSnackbar({
          message: error.toString(),
          options: {
            variant: 'error',
          },
        }))
      )
  }
}

function forgotPassword (email) {
  return dispatch => {
    dispatch(request())

    userService.forgotPassword(email)
      .then(
        () => dispatch(success()),
        error => {
          dispatch(failure(error))
          dispatch(notifierActions.enqueueSnackbar({
            message: error.response.status === 451 ? `Sorry, the address ${email} is not known to Shipshape.` : error.toString(),
            options: {
              variant: 'error',
            },
          }))
        }
      )
  }

  function request () {
    return { type: userConstants.FORGOT_PASSWORD_REQUEST }
  }

  function success (users) {
    return { type: userConstants.FORGOT_PASSWORD_SUCCESS }
  }

  function failure (error) {
    return { type: userConstants.FORGOT_PASSWORD_FAILURE }
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete (id) {
  return dispatch => {
    dispatch(request(id))

    userService.delete(id)
      .then(
        user => {
          dispatch(success(id))
        },
        error => {
          dispatch(failure(id, error))
        }
      )
  }

  function request (id) {
    return { type: userConstants.DELETE_REQUEST, id }
  }

  function success (id) {
    return { type: userConstants.DELETE_SUCCESS, id }
  }

  function failure (id, error) {
    return { type: userConstants.DELETE_FAILURE, id, error }
  }
}

function getUserNotifications () {
  return dispatch => {
    dispatch(request())
    try {
      const notifications = notificationService.getNotifications()
      dispatch(success(notifications))
    } catch (e) {
      dispatch(failure(e))
    }
  }

  function request () {
    return { type: userConstants.GET_NOTIFICATIONS_REQUEST }
  }

  function success (notifications) {
    return { type: userConstants.GET_NOTIFICATIONS_SUCCESS, notifications }
  }

  function failure (error) {
    return { type: userConstants.GET_NOTIFICATIONS_FAILURE, error }
  }
}

function closeNotification (key) {
  return dispatch => {
    try {
      const notifications = notificationService.closeNotification(key)
      dispatch(success(notifications))
    } catch (e) {
      dispatch(failure(e))
    }
  }

  function success (notifications) {
    return { type: userConstants.UPDATE_NOTIFICATIONS, notifications }
  }

  function failure (error) {
    return { type: userConstants.UPDATE_NOTIFICATIONS_FAILURE, error }
  }
}
